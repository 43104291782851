<template>

  <div class="container" style="width:97%;">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="small"
          icon="el-icon-plus"
          style="margin-bottom: 10px"
          @click="clickAddItem">添加
      </el-button>
      <el-input style="width: 180px;margin-left: 30px;" placeholder="请输入项目名称" v-model="inputSearch"
                @keydown.enter.prevent="searchItem">
<!--        <template #append>-->
<!--          <el-button icon="el-icon-search" @click="searchItem"/>-->
<!--        </template>-->
      </el-input>
      <el-select v-model="isSeal" placeholder="请选择" style="width: 100px;margin-bottom: 10px;margin-left: 10px;" clearable>
        <el-option label="未封存" value="false"/>
        <el-option label="已封存" value="true"/>
      </el-select>
      <el-button icon="el-icon-search" type="success" style="margin-bottom: 10px;margin-left: 10px;" size="small"
                 @click="searchItem">查询
      </el-button>

      <el-button
          class="filter-item"
          type="primary"
          size="small"
          icon="el-icon-refresh"
          style="margin-bottom: 10px;margin-left: 5px;"
          @click="resetSearch">重置
      </el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;">
      <el-table-column label="项目名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.projectName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.customerName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目负责人" align="center">
        <template v-slot="{row}">
          <span>{{ row.projectLeader }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同金额" align="center">
        <template v-slot="{row}">
          <span>{{ row.contractPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售金额" align="center">
        <template v-slot="{row}">
          <span :style="{ color: isPriceSame(row) ? 'black' : 'red' }">{{ row.totalSalesPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="未收款" align="center">
        <template v-slot="{row}">
          <span>{{ row.needReceivedPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="未付款" align="center">
        <template v-slot="{row}">
          <span>{{ row.needPaidPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{row}">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="230px"
          fixed="right">
        <template v-slot="{row}">
          <el-button type="success" size="mini" @click="visitItem(row)">查看</el-button>
          <el-button type="primary" size="mini" @click="editItem(row)">编辑</el-button>
          <el-button type="warning" size="mini" @click="sealItem(row.strID)" v-if="!row.isSeal">封存</el-button>
          <el-button type="info" size="mini" v-else>已封存</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.pageNum"
          :page-size="listQuery.pageCount"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <!--    点击添加按钮-->
    <el-dialog width="50%" v-model="dialogFormVisible" :close-on-click-modal="closeOnClick" :before-close="handleClose"
               :close-on-press-escape="closeOnClick"
               title="项目添加">
      <el-form
          ref="addForm"
          :model="formModel"
          :rules="rules"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectName">
              <el-input style="width: 200px"
                        v-model="formModel.projectName"
                        placeholder="请填写项目名称"/>
            </el-form-item>
            <el-form-item label="项目负责人" prop="projectLeader">
              <el-input style="width: 200px"
                        v-model="formModel.projectLeader"
                        placeholder="请填写项目负责人"/>
            </el-form-item>
            <el-form-item label="已收款">
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.receivedPrice"
                        @blur="inputBlur('receivedPrice')"
                        placeholder="请填写已收款金额"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customerName">
              <el-input style="width: 200px"
                        v-model="formModel.customerName"
                        placeholder="请填写客户名称"/>
            </el-form-item>
            <el-form-item label="合同金额" prop="contractPrice">
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.contractPrice"
                        @blur="inputBlur('contractPrice')"
                        placeholder="请填写合同金额"/>
            </el-form-item>
            <el-form-item label="已付款">
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.paidPrice"
                        @blur="inputBlur('paidPrice')"
                        placeholder="请填写已付款金额"/>
            </el-form-item>
          </el-col>
        </el-row>
        <p style="margin-left: 15px;">销售内容：</p>
        <el-row>
          <div style="margin-left: 20px;margin-top: 10px">
            <el-select filterable v-model="saleItem.productId" placeholder="请选择项目" style="width: 160px;" @change="saleItemChange">
              <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-input style="width: 120px;margin-left: 15px;"
                      type="number"
                      v-model="saleItem.productPrice"
                      @blur="inputBlur('productPrice')"
                      placeholder="成本"/>
            <el-input style="width: 120px;margin-left: 15px;"
                      type="number"
                      v-model="saleItem.salesPrice"
                      @blur="inputBlur('salesPrice')"
                      placeholder="销售价"/>
            <el-input style="width: 100px;margin:0 15px"
                      type="number"
                      v-model="saleItem.productCount"
                      @blur="inputBlur('productCount')"
                      placeholder="数量"/>
            <el-button type="success" plain @click="addsaleItem">添加</el-button>
          </div>
        </el-row>
        <el-row v-show="formModel.saleItems.length !== 0">
          <el-table
              :data="formModel.saleItems"
              border
              style="width: 850px;margin-top: 15px;margin-left: 20px;overflow: hidden">
            <el-table-column
                label="名称"
                prop="productName"
                align="center"
                width="200">
            </el-table-column>
            <el-table-column
                label="成本"
                prop="productPrice"
                align="center"
                width="180">
            </el-table-column>
            <el-table-column
                label="销售价"
                prop="salesPrice"
                width="170"
                align="center">
            </el-table-column>
            <el-table-column
                label="数量"
                prop="productCount"
                width="170"
                align="center">
            </el-table-column>
            <el-table-column
                align="center"
                label="操作">
              <template #default="scope">
                <el-button
                    @click.native.prevent="deleteSaleItemsRow(scope.$index)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">合同：</p>
        <el-row>
          <!--          貌似PDF无法显示图片-->
          <el-upload id="upload"
                     class="upload-demo"
                     accept=".pdf"
                     ref="addAgreementUpload"
                     :action="host"
                     :data="new_multipart_params"
                     multiple
                     :before-upload="agreementBeforeUpload"
                     :on-preview="agreementHandlePictureCardPreview"
                     :on-change="agreementHandleChange"
                     :on-remove="agreementHandleRemove"
                     :on-success="agreementHandleSuccess"
                     :file-list="agreementList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传PDF文件</div>
          </el-upload>
        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">进项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <!--          <el-upload-->
          <!--              list-type="picture-card"-->
          <!--              accept=".png,.jpg,.jepg"-->
          <!--              :action="uploadUrl"-->
          <!--              :headers="picheaders"-->
          <!--              :data="uploadtype"-->
          <!--              :before-upload="beforeUpload"-->
          <!--              :on-preview="handlePictureCardPreview"-->
          <!--              :on-change="handleChange"-->
          <!--              :on-remove="handleRemove"-->
          <!--              :on-success="handleSuccess"-->
          <!--              :file-list="fileList">-->
          <!--            <i class="el-icon-plus"></i>-->
          <!--          </el-upload>-->
          <el-upload
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              ref="addInUpload"
              :action="host"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">销项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <el-upload
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              ref="addOutUpload"
              :action="host"
              :data="new_multipart_params"
              :before-upload="SalesInvoiceImgBeforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="SalesInvoiceImghandleChange"
              :on-remove="SalesInvoiceImghandleRemove"
              :on-success="SalesInvoiceImghandleSuccess"
              :file-list="SalesInvoiceImgList">
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-row>
      </el-form>
      <div name="footer" class="dialog-footer" style="float: right;border: 10px">
        <el-button type="primary" @click="sureAddItem()">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <el-dialog width="50%" v-model="dialogFormEditVisible" :before-close="handleClose"
               :close-on-click-modal="closeOnClick" :close-on-press-escape="closeOnClick"
               title="项目编辑">
      <el-form
          ref="dataForm"
          :model="formModel"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称">
              <el-input style="width: 200px"
                        v-model="formModel.projectName"
                        disabled
                        placeholder="请填写项目名称"/>
            </el-form-item>
            <el-form-item label="项目负责人">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectLeader"
                        placeholder="请填写项目负责人"/>
            </el-form-item>
            <el-form-item label="已收款">
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.receivedPrice"
                        @blur="inputBlur('receivedPrice')"
                        placeholder="请填写已收款金额"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.customerName"
                        placeholder="请填写客户名称"/>
            </el-form-item>
<!--            disabled-->
            <el-form-item label="合同金额">
              <el-input style="width: 200px"
                        type="number"
                        
                        v-model="formModel.contractPrice"
                        @blur="inputBlur('contractPrice')"
                        placeholder="请填写合同金额"/>
            </el-form-item>
            <el-form-item label="已付款">
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.paidPrice"
                        @blur="inputBlur('paidPrice')"
                        placeholder="请填写已付款金额"/>
            </el-form-item>
          </el-col>
        </el-row>
        <p style="margin-left: 15px;">销售内容：</p>
        <el-row>
          <div style="margin-left: 20px;margin-top: 10px">
            <el-select filterable v-model="saleItem.productId" placeholder="请选择项目" style="width: 160px;" @change="saleItemChange">
              <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-input style="width: 120px;margin-left: 15px;"
                      type="number"
                      v-model="saleItem.productPrice"
                      @blur="inputBlur('productPrice')"
                      placeholder="成本"/>
            <el-input style="width: 120px;margin-left: 15px;"
                      type="number"
                      v-model="saleItem.salesPrice"
                      @blur="inputBlur('salesPrice')"
                      placeholder="销售价"/>
            <el-input style="width: 100px;margin:0 15px"
                      type="number"
                      v-model="saleItem.productCount"
                      @blur="inputBlur('productCount')"
                      placeholder="数量"/>
            <el-button type="success" plain @click="addsaleItem">添加</el-button>
          </div>
        </el-row>
        <el-row v-show="formModel.saleItems.length !== 0">
          <el-table
              :data="formModel.saleItems"
              border
              style="width: 850px;margin-top: 15px;margin-left: 20px;overflow: hidden">
            <el-table-column
                label="名称"
                prop="productName"
                align="center"
                width="200">
            </el-table-column>
            <el-table-column
                label="成本"
                prop="productPrice"
                align="center"
                width="180">
            </el-table-column>
            <el-table-column
                label="销售价"
                prop="salesPrice"
                width="170"
                align="center">
            </el-table-column>
            <el-table-column
                label="数量"
                prop="productCount"
                width="170"
                align="center">
            </el-table-column>
            <el-table-column
                align="center"
                label="操作">
              <template #default="scope">
                <el-button
                    @click.native.prevent="deleteSaleItemsRow(scope.$index)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
<!--        (不可删除)-->
        <p style="margin-left: 15px;margin-top: 15px">合同：</p>
<!--        修改之后的-->
        <el-row>
          <el-upload id="upload"
                     class="upload-demo"
                     accept=".pdf"
                     ref="addAgreementUpload"
                     :action="host"
                     :data="new_multipart_params"
                     multiple
                     :before-upload="agreementBeforeUpload"
                     :on-preview="agreementHandlePictureCardPreview"
                     :on-change="agreementHandleChange"
                     :on-remove="agreementHandleRemove"
                     :on-success="agreementHandleSuccess"
                     :file-list="agreementList">
            <el-button size="small" type="primary">点击添加</el-button>
            <div slot="tip" class="el-upload__tip">只能上传PDF文件</div>
          </el-upload>
        </el-row>
<!--        修改之前的 2024/1/25-->
<!--        <el-row>-->
<!--          <el-upload-->
<!--              class="upload-demo uploadpdf"-->
<!--              accept=".pdf"-->
<!--              :headers="picheaders"-->
<!--              :data="uploadAgreementtype"-->
<!--              multiple-->
<!--              :on-preview="agreementHandlePictureCardPreview"-->
<!--              :file-list="agreementList">-->
<!--          </el-upload>-->
<!--        </el-row>-->
        <p style="margin-left: 15px;margin-top: 15px">进项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <el-upload
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              ref="editInUpload"
              :action="host"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">销项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <el-upload
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              ref="editOutUpload"
              :action="host"
              :data="new_multipart_params"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="SalesInvoiceImghandleChange"
              :on-remove="SalesInvoiceImghandleRemove"
              :on-success="SalesInvoiceImghandleSuccess"
              :file-list="SalesInvoiceImgList">
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-row>
      </el-form>
      <div name="footer" class="dialog-footer" style="float: right;border: 10px">
        <!--        <el-button @click="dialogFormVisible = false">取消</el-button>-->

        <el-button type="primary" @click="sureEditItem()">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <!--    點擊查看按鈕-->
    <el-dialog width="50%" title="项目查看" v-model="dialogVisitVisible" :close-on-click-modal="closeOnClick"
               :close-on-press-escape="closeOnClick">
      <el-form
          ref="dataForm"
          :model="formModel"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectName"
                        placeholder="请填写项目名称"/>
            </el-form-item>
            <el-form-item label="项目负责人">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectLeader"
                        placeholder="请填写项目负责人"/>
            </el-form-item>
            <el-form-item label="已收款">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.receivedPrice"
                        @blur="inputBlur('receivedPrice')"
                        placeholder="请填写已收款金额"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.customerName"
                        placeholder="请填写客户名称"/>
            </el-form-item>
            <el-form-item label="合同金额">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.contractPrice"
                        @blur="inputBlur('contractPrice')"
                        placeholder="请填写合同金额"/>
            </el-form-item>
            <el-form-item label="已付款">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.paidPrice"
                        @blur="inputBlur('paidPrice')"
                        placeholder="请填写已付款金额"/>
            </el-form-item>
          </el-col>
        </el-row>
        <p style="margin-left: 15px;">销售内容：</p>
        <el-row v-show="formModel.saleItems.length !== 0">
          <el-table
              :data="formModel.saleItems"
              border
              style="width: 735px;margin-top: 15px;margin-left: 20px;overflow: hidden">
            <el-table-column
                prop="productName"
                label="名称"
                align="center"
                width="200">
            </el-table-column>
            <el-table-column
                prop="productPrice"
                align="center"
                label="成本"
                width="180">
            </el-table-column>
            <el-table-column
                prop="salesPrice"
                align="center"
                label="销售价"
                width="180">
            </el-table-column>
            <el-table-column
                prop="productCount"
                width="170"
                label="数量"
                align="center">
            </el-table-column>
          </el-table>
        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">合同：</p>
        <el-row>
          <!--          貌似PDF无法显示图片-->
          <el-upload class="upload-demo uploadpdf"
                     accept=".pdf"
                     :headers="picheaders"
                     :data="uploadAgreementtype"
                     multiple
                     :before-upload="agreementBeforeUpload"
                     :on-preview="agreementHandlePictureCardPreview"
                     :on-change="agreementHandleChange"
                     :on-remove="agreementHandleRemove"
                     :on-success="agreementHandleSuccess"
                     :file-list="agreementList">
            <!--            <el-button size="small" type="primary">点击上传</el-button>-->
            <!--            <div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
          </el-upload>
        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">进项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <el-upload
              id="uploadVisitjinxiang"
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              :headers="picheaders"
              :data="uploadtype"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="handleChange"
              :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-row>
        <p style="margin-left: 15px;margin-top: 15px">销项发票：</p>
        <el-row style="padding-left: 20px;padding-top: 15px;">
          <el-upload
              id="uploadVisitxiaoxiang"
              list-type="picture-card"
              accept=".png,.jpg,.jepg"
              :headers="picheaders"
              :data="uploadtype"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="SalesInvoiceImghandleChange"
              :on-remove="SalesInvoiceImghandleRemove"
              :on-success="SalesInvoiceImghandleSuccess"
              :file-list="SalesInvoiceImgList">
            <i class="el-icon-plus"></i>
          </el-upload>

        </el-row>
      </el-form>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>

</template>
<script>
import $ from 'jquery'
import global from "@/utils/global";
import md5 from 'js-md5';
import Compressor from 'compressorjs';

export default {
  name: "Item",
  data() {
    return {
      inputSearch: '',
      isSeal: '',

      closeOnClick: false,
      //图片上传
      uploadtype: {
        type: 'image'
      },
      uploadAgreementtype: {
        type: 'doc'
      },
      picheaders: {
        'Authorization': localStorage.getItem("token")
      },
      fileList: [],
      SalesInvoiceImgList: [],
      agreementList: [],

      //判斷是否是添加按鈕從而走不同的方法
      dialogImageUrl: '',
      dialogVisible: false,

      dialogVisitVisible: false,
      dialogFormEditVisible: false,

      productList: [],

      list: [],
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      listQuery: {
        "pageCount": 10,
        "pageNum": 1,
        "search": {
        }
      },
      saleItemId: '',
      saleItem: {
        productId: '',
        "productCount": '',
        "productName": "",
        "productPrice": '',
        salesPrice: ''
      },
      formModel: {
        "contractPrice": "",
        "totalSalesPrice": "",
        "contracts": [],
        "customerName": "",
        "inputReceipts": [],
        "outReceipts": [],
        "paidPrice": "",
        "projectLeader": "",
        "projectName": "",
        "receivedPrice": "",
        "saleItems": []
      },
      rules: {
        projectName: [{
          required: true, message: '请填写项目名称', trigger: 'blur'
        }],
        customerName: [
          {required: true, message: '请填写客户名称', trigger: 'blur'}
        ],
        projectLeader: [
          {required: true, message: '请填写项目负责人', trigger: 'blur'}
        ],
        contractPrice: [
          {required: true, message: '请填写合同金额', trigger: 'blur'}
        ],
      },

      //阿里云OSS存储
      accessid: '',
      accesskey: '',
      host: '',
      policyBase64: '',
      signature: '',
      callbackbody: '',
      filename: '',
      key: '',
      expire: 0,
      g_object_name: '',
      g_object_name_type: '',
      now: Date.parse(new Date()) / 1000,
      new_multipart_params: {}
    }
  },
  created() {
    this.getList();
    this.getProductList();
  },
  computed: {
    selectedProduct() {
      if (this.saleItem.productId !== null) {
        return this.productList.find(item => item.id === this.saleItem.productId);
      }
      return null;
    },
  },
  methods: {
    isPriceSame(row) {
      return parseFloat(row.contractPrice) === parseFloat(row.totalSalesPrice);
    },
    random_string(len) {
      len = len || 32;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    get_suffix(filename) {
      var pos = filename.lastIndexOf('.')
      var suffix = ''
      if (pos != -1) {
        suffix = filename.substring(pos)
      }
      return suffix;
    },
    calculate_object_name(filename, type) {
      var suffix = this.get_suffix(filename)
      this.g_object_name = type + '/' + this.random_string(10) + filename.substring(0, filename.lastIndexOf('.')) + suffix
      return ''
    },
    // calculate_object_name(filename) {
    //   this.g_object_name = filename
    // },
    set_upload_param(filename, ret, type) {
      if (ret === false) {
        ret = this.get_signature()
      }
      if (filename !== '') {
        this.calculate_object_name(filename, type)
      }
      this.new_multipart_params = {
        'key': this.g_object_name,
        'policy': this.policyBase64,
        'OSSAccessKeyId': this.accessid,
        'success_action_status': '200', //让服务端返回200,不然，默认会返回204
        'callback': this.callbackbody,
        'signature': this.signature,
      };
    },
    //aliyun OSS存储获取签名
    get_signature() {
      // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
      this.now = Date.parse(new Date()) / 1000;

      // if (this.expire < this.now + 3) {
      var body = this.send_request()
      var obj = JSON.parse(body)
      this.host = obj['host'];
      //http方式发送有问题
      this.host = this.host.replace("http", "https");
      this.policyBase64 = obj['policy'];
      this.accessid = obj['accessid'];
      this.signature = obj['signature'];
      this.expire = parseInt(obj['expire']);
      this.callbackbody = obj['callback'];
      this.key = obj['dir'];
      // }
    },
    send_request() {
      var str = {}
      $.ajax({
        url: global.syOssUrl, // 后面改为全局变量
        type: 'get',
        async: false, // 同步请求
        headers: {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23' + this.now)
        },
        data: {},
        timeout: 10000,
        dataType: 'json',
        success: function (response) {
          str = JSON.stringify(response)
        },
      });
      // console.log(str)
      return str
    },
    //jsonp请求方式目的是为了跨域
    getImgUrl(url) {
      var str = {}
      var time = Date.parse(new Date()) / 1000;
      $.ajax({
        url: global.syOssUrl + '/o2u?objName=' + url, // 后面改为全局变量
        type: 'get',
        async: false, // 同步请求
        headers: {
          'timestamp': time,
          'sign': md5('6486b66b41f8df68777eac23' + time)
        },
        data: {},
        timeout: 10000,
        dataType: 'json',
        success: function (response) {
          str = response.msg
        },
      });
      return str
    },
    // handleCallback(res) {
    //   console.log(res.msg+'111')
    // },
    inputBlur(string) {
      if (string === 'contractPrice') {
        this.formModel.contractPrice = parseFloat(this.formModel.contractPrice)
        this.formModel.contractPrice = this.formatNumber(this.formModel.contractPrice)
      } else if (string === 'productPrice') {
        this.saleItem.productPrice = parseFloat(this.saleItem.productPrice)
        // this.saleItem.productPrice = this.formatNumber(this.saleItem.productPrice)
      } else if (string === 'paidPrice') {
        this.formModel.paidPrice = parseFloat(this.formModel.paidPrice)
        this.formModel.paidPrice = this.formatNumber(this.formModel.paidPrice)
      } else if (string === 'salesPrice') {
        this.saleItem.salesPrice = parseFloat(this.saleItem.salesPrice)
        // this.saleItem.salesPrice = this.formatNumber(this.saleItem.salesPrice)
      } else if (string === 'receivedPrice') {
        this.formModel.receivedPrice = parseFloat(this.formModel.receivedPrice)
        this.formModel.receivedPrice = this.formatNumber(this.formModel.receivedPrice)
      } else if (string === 'productCount') {
        this.saleItem.productCount = parseInt(this.saleItem.productCount)
      }
    },
    formatNumber(number) {
      const str = number.toString();

      if (str.includes('.')) {
        const decimalLength = str.split('.')[1].length;

        if (decimalLength > 2) {
          return number.toFixed(2);
        } else if (decimalLength === 1) {
          return number.toFixed(1);
        }
      }

      return number;
    },
    resetSearch() {
      this.inputSearch = ''
      this.isSeal = ''
      this.searchItem()
    },
    toBool(bool){
      if (bool === 'true')
          return true
      if (bool === 'false')
          return false
    },
    searchItem() {
      this.listLoading = true
      this.listQuery = {
        "pageCount": 10,
        "pageNum": 1,
        "search":{}
      }
      if (this.inputSearch !== '') {
        this.listQuery.search["ProjectName"] = this.inputSearch
      }
      if (this.isSeal !== '') {
        this.listQuery.search["IsSeal"] = this.toBool(this.isSeal)
      }
      this.$axios({
        url: this.localpath + 'saleproject/listSaleProject',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.list = res.data.msg.projects
          this.total = res.data.msg.total
          this.$message({
            message: '查询成功',
            type: 'success'
          })
          this.listLoading = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //项目封存
    sealItem(strID) {
      this.$confirm('确定封存吗？封存后不可再编辑', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
          .then(() => {
            this.sealItemReq(strID)
          })
          .catch(action => {

          });
    },
    sealItemReq(strID) {
      this.$axios({
        url: this.localpath + 'saleproject/sealProject',
        method: 'post',
        //发送格式为json
        data: JSON.stringify({strId: strID}),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '封存成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    },
    saleItemChange() {
      if (this.selectedProduct !== null) {
        // 在这里可以使用 this.selectedProduct 对象进行相关操作
        var product = this.selectedProduct
        this.saleItem.productName = product.name
        this.saleItem.productPrice = product.price
      }
    },
    visitItem(row) {
      this.formModel = row
      this.fileList = []
      this.agreementList = []
      this.SalesInvoiceImgList = []
      for (var i in this.formModel.inputReceipts) {
        this.fileList.push({url: this.getImgUrl(this.formModel.inputReceipts[i])})
      }
      //想要获取名字，必须上传名字
      for (var i in this.formModel.contracts) {
        this.agreementList.push({
          url: this.formModel.contracts[i],
          name: this.formModel.contracts[i].substring(this.formModel.contracts[i].lastIndexOf('/') + 11)
        })
      }
      for (var i in this.formModel.outReceipts) {
        this.SalesInvoiceImgList.push({url: this.getImgUrl(this.formModel.outReceipts[i])})
      }
      this.dialogVisitVisible = true
    },
    clickAddItem() {
      this.formModel = {
        "contractPrice": "",
        "contracts": [],
        "customerName": "",
        "inputReceipts": [],
        "outReceipts": [],
        "paidPrice": "",
        "projectLeader": "",
        "projectName": "",
        "receivedPrice": "",
        "saleItems": []
      }
      this.fileList = []
      this.agreementList = []
      this.SalesInvoiceImgList = []
      this.dialogFormVisible = true
    },
    getProductList() {
      var productListQuery = {
        "pageCount": 999,
        "pageNum": 1,
      }
      this.$axios({
        url: this.localpath + 'saleproject/listProduct',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(productListQuery),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.productList = res.data.msg.products
          // this.total = res.data.msg.total
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    editItem(row) {
      if (row.isSeal) {
        this.$message({
          message: '项目已封存，不可编辑',
          type: 'info'
        })
        return
      }
      this.formModel = {
        contractPrice: row.contractPrice,
        contracts: row.contracts,
        customerName: row.customerName,
        inputReceipts: row.inputReceipts,
        outReceipts: row.outReceipts,
        paidPrice: row.paidPrice,
        projectLeader: row.projectLeader,
        projectName: row.projectName,
        receivedPrice: row.receivedPrice,
        saleItems: row.saleItems,
        strID: row.strID
      }
      this.fileList = []
      this.agreementList = []
      this.SalesInvoiceImgList = []
      for (var i in this.formModel.inputReceipts) {
        this.fileList.push({url: this.getImgUrl(this.formModel.inputReceipts[i])})
      }
      //想要获取名字，必须上传名字
      for (var i in this.formModel.contracts) {
        this.agreementList.push({
          url: this.formModel.contracts[i],
          name: this.formModel.contracts[i].substring(this.formModel.contracts[i].lastIndexOf('/') + 11)
        })
      }
      for (var i in this.formModel.outReceipts) {
        this.SalesInvoiceImgList.push({url: this.getImgUrl(this.formModel.outReceipts[i])})
      }

      this.dialogFormEditVisible = true

    },
    sureEditItem() {
      if (this.formModel.paidPrice === '') {
        this.formModel.paidPrice = '0'
      }
      if (this.formModel.receivedPrice === '') {
        this.formModel.receivedPrice = '0'
      }
      this.formModel.contractPrice = parseFloat(this.formModel.contractPrice)
      this.formModel.paidPrice = parseFloat(this.formModel.paidPrice)
      this.formModel.receivedPrice = parseFloat(this.formModel.receivedPrice)
      this.$axios({
        url: this.localpath + 'saleproject/updateSaleProject',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(this.formModel),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.dialogFormEditVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.dialogFormEditVisible = false
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    },
    sureAddItem() {
      if (this.formModel.contracts.length === 0) {
        this.$message({
          message: '请选择合同',
          type: 'error'
        })
        return
      }
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.formModel.paidPrice === '') {
            this.formModel.paidPrice = '0'
          }
          if (this.formModel.receivedPrice === '') {
            this.formModel.receivedPrice = '0'
          }
          this.formModel.contractPrice = parseFloat(this.formModel.contractPrice)
          this.formModel.paidPrice = parseFloat(this.formModel.paidPrice)
          this.formModel.receivedPrice = parseFloat(this.formModel.receivedPrice)

          this.$axios({
            url: this.localpath + 'saleproject/createSaleProject',
            method: 'post',
            //发送格式为json
            data: JSON.stringify(this.formModel),
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then(res => {
            if (res.data.code === 0) {
              this.dialogFormVisible = false
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        }
      });
    },
    addsaleItem() {


      //验证空
      if (this.saleItem.productName === '' || this.saleItem.productPrice === '' || this.saleItem.productCount === '' || this.saleItem.salesPrice === '') {
        this.$message({
          message: '请填写完整的销售内容',
          type: 'error'
        })
        return
      }
      //字符串转为数字
      this.saleItem.productCount = parseInt(this.saleItem.productCount)
      this.saleItem.productPrice = parseFloat(this.saleItem.productPrice)
      this.saleItem.salesPrice = parseFloat(this.saleItem.salesPrice)
      //防止浅复制
      var item = JSON.parse(JSON.stringify(this.saleItem))
      //转为数字
      this.formModel.saleItems.splice(0, 0, item);
      this.saleItem = {
        productId: '',
        "productCount": '',
        "productName": "",
        "productPrice": "",
        salesPrice: ''
      }
    },

    beforeUpload(file) {

      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.set_upload_param(file.name, false, 'receipt');
        new Compressor(file, {
          quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },

    handlePictureCardPreview(file) {
      //默认是下载而不是打开
      // window.open(file.url)
      var newWindow = window.open();
      newWindow.document.write('<html><body><img src="' + file.url + '"></body></html>');
    },

    handleChange(file, fileList) {
      if (fileList.length > this.fileList.length) {
        //添加了一张图片
      }
      this.fileList = fileList
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList
      //移除this.formModel.inputReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.inputReceipts) {
        if (this.formModel.inputReceipts[i] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.inputReceipts.splice(removeindex, 1)
    },
    handleSuccess(response, file, fileList) {
      if (response.Status !== undefined && response.Status === "OK") {
        var imgurl = this.g_object_name
        this.formModel.inputReceipts.push(imgurl)
      } else {
        this.$message({
          message: "上传失败",
          type: 'error'
        });

        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.fileList = fileList
      }
    },

    //图片操作
    SalesInvoiceImghandlePictureCardPreview(file) {
      //默认是下载而不是打开
      window.open(file.url)
    },
    SalesInvoiceImgBeforeUpload(file) {

      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.set_upload_param(file.name, false, 'receipt');
        new Compressor(file, {
          quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    SalesInvoiceImghandleChange(file, fileList) {
      this.SalesInvoiceImgList = fileList
    },
    // 上传图片移除
    SalesInvoiceImghandleRemove(file, fileList) {
      this.SalesInvoiceImgList = fileList

      //移除this.formModel.outReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.outReceipts) {
        if (this.formModel.outReceipts[i] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.outReceipts.splice(removeindex, 1)
    },
    SalesInvoiceImghandleSuccess(response, file, fileList) {
      if (response.Status !== undefined && response.Status === "OK") {
        var imgurl = this.g_object_name
        this.formModel.outReceipts.push(imgurl)
      } else {
        this.$message({
          message: "上传失败",
          type: 'error'
        });

        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.SalesInvoiceImgList = fileList

        console.log(file)
        console.log(fileList)
      }
    },
    agreementHandlePictureCardPreview(file) {
      const url = this.getImgUrl(file.url)
      console.log(url)
      window.open(url)
    },
    agreementBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'pdf'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning'
        });
      }
      return new Promise((resolve) => {
        this.set_upload_param(file.name, false, 'contract');
        resolve(file);
      });
    },
    agreementHandleChange(file, fileList) {

      this.agreementList = fileList
    },
    // 上传图片移除
    agreementHandleRemove(file, fileList) {
      this.agreementList = fileList
      //移除this.formModel.outReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.contracts) {
        if (this.formModel.contracts[i].split(',')[0] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.contracts.splice(removeindex, 1)
    },
    agreementHandleSuccess(response, file, fileList) {
      console.log(fileList)
      // !-----------------问题上传失败依旧显示  成功
      if (response.Status !== undefined && response.Status === "OK") {
        //加上url，上傳後不知道為什麼沒有url
        var imgurl = this.g_object_name
        file.url = imgurl
        fileList.splice(fileList.length - 1, 1)
        fileList.push(file)
        this.agreementList = fileList

        this.formModel.contracts.push(imgurl)
      } else {
        this.$message({
          message: "上传失败",
          type: 'error'
        });
        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.agreementList = fileList

        console.log(file)
        console.log(fileList)
      }
    },

    deleteSaleItemsRow(index) {
      this.formModel.saleItems.splice(index, 1)
    },
    //图书查询
    getList() {
      this.dialogFormVisible = false
      this.listLoading = true
      // this.getlist(this.formModel).then(res => {
      this.$axios({
        url: this.localpath + 'saleproject/listSaleProject',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.list = res.data.msg.projects
          this.total = res.data.msg.total
          // this.$message({
          //   message: '图书信息已获取',
          //   type: 'success'
          // })
          this.listLoading = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭吗？内容不会被保存')
          .then(_ => {
            this.dialogVisitVisible = false
            this.dialogFormEditVisible = false
            this.dialogFormVisible = false
            done();
          })
          .catch(_ => {
          });
    },
    currPageChange(Page) {
      this.listQuery.pageNum = Page
      this.getList()
    },
    pageSizeChange(Perpage) {
      this.listQuery.pageCount = Perpage
      this.listQuery.pageNum = 1
      this.getList()
    }
  },
}
</script>

<style scoped>
#upload {
  margin-left: 20px;
  margin-top: 15px;
}

#upload :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 55px;
}

.uploadpdf {
  margin-left: 20px;
  /*margin-top: 15px;*/
}

.uploadpdf :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 0;
}

.uploadpdf :deep() .el-icon-close {
  display: none;
}

.uploadpdf :deep() .el-icon-close-tip {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#upload :deep() .el-upload {
  text-align: left;
}

.el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
</style>
